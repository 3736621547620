import {inject, Injectable} from '@angular/core';
import {RealmClientService} from './realm-client.service';
import {from} from 'rxjs';
import {ENVIRONMENT_TOKEN} from '../types/environment.token';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  private _realmClientService = inject(RealmClientService);
  private _environmentToken = inject(ENVIRONMENT_TOKEN);

  constructor() {
  }

  getOrganisationList() {
    return from(
      // tslint:disable-next-line:max-line-length
      this._realmClientService.mongoRemoteClient.db(this._environmentToken.databaseName).collection(this._environmentToken.organisationCollection).find({})
    );
  }

  getOrganisationById(orgId: string) {
    return from(
      // tslint:disable-next-line:max-line-length
      this._realmClientService.mongoRemoteClient.db(this._environmentToken.databaseName).collection(this._environmentToken.organisationCollection).find({org_id: orgId})
    ); // TODO: need to check variable org_id
  }
}

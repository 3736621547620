import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { AbstractBlockComponent } from "../../abstract-block/abstract-block/abstract-block.component";
import { VideoBlock } from "../../types/block";
import { isNullOrUndefined } from "@swimlane/ngx-datatable";
import { Subscription } from "rxjs";
import { CommonModule } from "@angular/common";
import { AbstractBlockModule } from "../../abstract-block/abstract-block.module";

@Component({
  selector: "ail-video-block",
  template: "",
  standalone: true,
  imports: [CommonModule, AbstractBlockModule]
})
export class VideoBlockComponent extends AbstractBlockComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() declare data: VideoBlock;
  @Input() mode: string;

  @ViewChild("videoPlayerTemplateREF", { static: true, read: ViewContainerRef })
  _videoPlayerTemplateRef: HTMLVideoElement;
  videoSourceURL: string;
  hasVideoSourceULR = false;
  protected _videoPauseSubscription$: Subscription;

  constructor() {
    super();
  }

  ngOnInit() {
    if (!isNullOrUndefined(this.data)) {
      this._setCorrectVideoSource();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!isNullOrUndefined(changes["data"].currentValue)) {
      this._setCorrectVideoSource();
    }
  }

  ngOnDestroy(): void {
    this._videoPauseSubscription$.unsubscribe();
  }

  private _setCorrectVideoSource() {
    const mp4VideoFormat = this.data.source_url_list.find(
      videoSource => videoSource.format.toLowerCase() === MP4_FORMAT_EXTENSION
    );
    if (!isNullOrUndefined(mp4VideoFormat)) {
      this.videoSourceURL = mp4VideoFormat.source_url;
      this.hasVideoSourceULR = true;
    }
  }
}

export const MP4_FORMAT_EXTENSION = "mp4";

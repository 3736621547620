import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class VideoBlockService {
  onVideoPlaying$: BehaviorSubject<string> = new BehaviorSubject<string>("");

  onPageChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  questPageChanged(){
    this.onPageChange$.next(true);
  }

}

import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import {
  defineCustomElements,
  applyPolyfills
} from "mindlife-custom-battery-rangeslider/loader";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
applyPolyfills().then(() => defineCustomElements());

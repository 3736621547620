<router-outlet></router-outlet>

<ng-template #userIdleContainer let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Inactivity Warning</h4>
    <button
      (click)="d('Cross click')"
      aria-label="Close"
      class="close"
      type="button"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col">
        <p>You will be automatically logged out in 1 minute to protect your data. If you are still working please click the appropriate button.</p>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="row full-width">
        <div class="col-6">
          <button type="button" class="btn btn-success btn-raised btn-block" (click)="keepOpen()">Stay Logged In</button>
        </div>
      <div class="col-6">
        <button type="button" class="btn btn-primary btn-raised btn-block" (click)="logout()">Logout</button>
      </div>
    </div>
  </div>
</ng-template>

import {
  ComponentRef,
  Inject,
  Injectable,
  ViewContainerRef
} from "@angular/core";
import { Block } from "../types/block";
import { AbstractBlockComponent } from "../abstract-block/abstract-block/abstract-block.component";
import { ComponentMapToken } from "../values/tokens";
import { ComponentMap } from "../types/module-config";
import { INPUT_BLOCK_LIST, SessionStoreService } from "./session-store.service";
import { INPUT_BLOCK_LOCKED_MODE } from "../input-block/input-block/input-block.component";
import { first, take, takeUntil } from "rxjs/operators";
import { fromEvent, Subject } from "rxjs";
import { QuestPageComponent } from "../session-page/session-page/quest-page.component";

@Injectable({
  providedIn: "root"
})
export class BlockUtilService {
  constructor(
    @Inject(ComponentMapToken) private _componentMapConfigToken: ComponentMap,
    private _sessionStoreService: SessionStoreService
  ) {}

  sortBlockList(blocks: Block[]): Block[] {
    let sortedArray: Block[] = [];

    for (let i = 0; i < blocks.length; i++) {
      const blockBeingSearched = blocks?.find(block => block?.position === i);
      if (blockBeingSearched !== undefined) {
        sortedArray[i] = blockBeingSearched;
      }
    }

    //Must Be And Editor Array
    if (sortedArray.length === 0 && blocks.length > 0) {
      sortedArray = blocks;
    }

    return sortedArray;
  }

  addBlocksToContainerRef(
    viewContainerRef: ViewContainerRef,
    sortedBlockList: Block[]
  ) {
    viewContainerRef.clear();
    for (let block of sortedBlockList) {
      const blockComponent = this._componentMapConfigToken[block?.type];
      if (blockComponent !== undefined) {
        const blockComponentRef: ComponentRef<AbstractBlockComponent> = viewContainerRef.createComponent(
          blockComponent
        );

        if (INPUT_BLOCK_LIST.includes(block.type)) {
          blockComponentRef.instance["abstractControlReady"]
            .pipe(take(1))
            .subscribe(abstractControl => {
              this._sessionStoreService.currentQuestInputFormGroup$
                .pipe(first())
                .subscribe(formGroup => {
                  formGroup.addControl(block.id, abstractControl);
                  formGroup.updateValueAndValidity();

                  this._sessionStoreService.currentQuestInputFormGroup$.next(
                    formGroup
                  );
                });
            });
        }

        blockComponentRef.instance.data = block;
      }
    }
  }

  addLockedBlocksToContainerRef(
    viewContainerRef: ViewContainerRef,
    sortedBlockList: Block[],
    hostComponent?: QuestPageComponent
  ) {
    viewContainerRef.clear();
    for (let block of sortedBlockList) {
      const blockComponent = this._componentMapConfigToken[block.type];
      if (blockComponent !== undefined) {
        const blockComponentRef: ComponentRef<AbstractBlockComponent> = viewContainerRef.createComponent(
          blockComponent
        );

        const OnBlockSelected = (
          block: Block,
          hostComponent: QuestPageComponent
        ) => {
          hostComponent.onBlockSelected.emit(block);
        };

        const STREAM_TO_CLOSE$ = new Subject<any>();
        STREAM_TO_CLOSE$.pipe(first());
        blockComponentRef.onDestroy(() => {
          STREAM_TO_CLOSE$.next(false);
        });

        blockComponentRef.instance.data = block;
        fromEvent(blockComponentRef.location.nativeElement, "click")
          .pipe(takeUntil(STREAM_TO_CLOSE$))
          .subscribe(() => {
            OnBlockSelected(blockComponentRef.instance.data, hostComponent);
          });

        if (INPUT_BLOCK_LIST.includes(block.type)) {
          blockComponentRef.instance["mode"] = INPUT_BLOCK_LOCKED_MODE;
        }
      }
    }
  }

  getPageCount(blocks: Block[]): number {
    let highestPageIndex = 0;

    blocks.forEach(block => {
      if (block.page > highestPageIndex) {
        highestPageIndex = block.page;
      }
    });

    return highestPageIndex + 1;
  }

  getPageBlockMap(blocks: Block[]): Array<Block[]> {
    let pageBlockMap = [];

    blocks.forEach(block => {
      if (!pageBlockMap[block.page]) {
        pageBlockMap[block.page] = [];
      }
      pageBlockMap[block.page][block.position] = block;
    });
    const FINAL_PAGE_BLOCK = [];
    pageBlockMap.forEach(page => {
      if (page) {
        FINAL_PAGE_BLOCK.push(page);
      }
    });
    return FINAL_PAGE_BLOCK;
  }
}

<div class="block full-width rounded">
  <div class="block-header full-width p-3" *ngIf="data?.header?.length > 0">
    {{ data?.header }}
  </div>
  <div class="block-body full-width">
    <div class="p-3 mb-0 full-width form-group">
      <ng-select
        *ngIf="data?.options.length"
        bindLabel="label"
        bindValue="value"
        [formControl]="inputFormControl"
        [items]="data?.options"
        [placeholder]="data?.config?.placeholder"
      ></ng-select>
    </div>
  </div>
</div>

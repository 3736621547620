import { Routes } from "@angular/router";
import {
  COMPLETED_QUEST_ENTRY_PATH,
  COMPLETED_QUEST_ROUTE,
  LIFE_PRACTICE_LIST_LP_ROUTE,
  LIFE_PRACTICE_ROUTE,
  PROTOCOL_DETAIL_ROUTE_PARAM,
  PROTOCOL_ROUTE,
  QUEST_PATH,
  UPDATE_PROFILE,
  WEBINAR_PATH,
  WEBINAR_ROUTE_PARAM,
} from "../../../../../sdk/src/lib/values/shared-paths";
import {
  LIFE_PRACTICE_BATTERY_LEVEL,
  LIFE_PRACTICE_BURNOUT_INDICATOR,
  LIFE_PRACTICE_BUTTON_MAPPING,
  LIFE_PRACTICE_BUTTON_MAPPING_VERSION_TWO,
  LIFE_PRACTICE_COGNITIVE_CRAFTING_VERSION_THREE,
  LIFE_PRACTICE_DRIVING_CHANGE,
  LIFE_PRACTICE_IMPROVING_RELATIONSHIPS,
  LIFE_PRACTICE_IMPROVING_RELATIONSHIPS_VERSION_TWO,
  LIFE_PRACTICE_MOVING_FORWARD,
  LIFE_PRACTICE_MOVING_FORWARD_GROUP_ONE,
  LIFE_PRACTICE_MOVING_FORWARD_VERSION_TWO,
  LIFE_PRACTICE_RECOGNISE_TRIGGERS_AND_BOOSTERS,
  LIFE_PRACTICE_SAYING_NO,
  LIFE_PRACTICE_SAYING_NO_VERSION_TWO,
  LIFE_PRACTICE_TASK_CRAFTING,
  LIFE_PRACTICE_THOUGHT_CHALLENGING,
  LIFE_PRACTICE_THOUGHT_DAIRY,
} from "../../../../../sdk/src/lib/values/life-practice-readable-ids";

export const CONTENT_ROUTES: Routes = [
  {
    path: QUEST_PATH,
    loadChildren: () =>
      import("../../quest/quest.module").then((m) => m.QuestModule),
  },
  {
    path: LIFE_PRACTICE_LIST_LP_ROUTE,
    loadComponent: () =>
      import("../../life-practice-list/life-practice-list.component").then(
        (module) => module.LifePracticeListComponent
      ),
  },
  {
    path: `${WEBINAR_PATH}/:${WEBINAR_ROUTE_PARAM}`,
    loadComponent: () =>
      import("../../webinar/webinar.component").then(
        (module) => module.WebinarComponent
      ),
  },
  {
    path: UPDATE_PROFILE,
    loadChildren: () =>
      import("../../profile-onboarding/profile-onboarding.module").then(
        (m) => m.ProfileOnboardingModule
      ),
  },
  // {
  //   path: `${LIFE_PRACTICE_ROUTE}/${PROTOCOL_ROUTE}/:${PROTOCOL_DETAIL_ROUTE_PARAM}`,
  //   children: [
  //     {
  //       path: LIFE_PRACTICE_IMPROVING_RELATIONSHIPS,
  //       loadChildren: () =>
  //         import(
  //           "../../life-practice-improving-relationships/life-practice-improving-relationships.module"
  //         ).then((module) => module.LifePracticeImprovingRelationshipsModule),
  //     },
  //     {
  //       path: LIFE_PRACTICE_IMPROVING_RELATIONSHIPS_VERSION_TWO,
  //       loadChildren: () =>
  //         import(
  //           "../../life-practice-improving-relationships-version-two/life-practice-improving-relationships-version-two.module"
  //         ).then(
  //           (module) =>
  //             module.LifePracticeImprovingRelationshipsVersionTwoModule
  //         ),
  //     },
  //     {
  //       path: LIFE_PRACTICE_TASK_CRAFTING,
  //       loadChildren: () =>
  //         import(
  //           "../../life-practice-task-crafting/life-practice-task-crafting.module"
  //         ).then((module) => module.LifePracticeTaskCraftingModule),
  //     },
  //     {
  //       path: LIFE_PRACTICE_BURNOUT_INDICATOR,
  //       loadChildren: () =>
  //         import(
  //           "../../life-practice-burnout-indicator/life-practice-burnout-indicator.module"
  //         ).then((module) => module.LifePracticeBurnoutIndicatorModule),
  //     },
  //     {
  //       path: LIFE_PRACTICE_DRIVING_CHANGE,
  //       loadChildren: () =>
  //         import(
  //           "../../life-practice-driving-change/life-practice-driving-change.module"
  //         ).then((module) => module.LifePracticeDrivingChangeModule),
  //     },
  //     {
  //       path: LIFE_PRACTICE_BATTERY_LEVEL,
  //       loadChildren: () =>
  //         import(
  //           "../../life-practice-battery/life-practice-battery.module"
  //         ).then((module) => module.LifePracticeBatteryModule),
  //     },
  //     {
  //       path: LIFE_PRACTICE_BUTTON_MAPPING,
  //       loadChildren: () =>
  //         import(
  //           "../../life-practice-button-mapping/life-practice-button-mapping.module"
  //         ).then((module) => module.LifePracticeButtonMappingModule),
  //     },
  //     {
  //       path: LIFE_PRACTICE_BUTTON_MAPPING_VERSION_TWO,
  //       loadChildren: () =>
  //         import(
  //           "../../life-practice-button-mapping-version-two/life-practice-button-mapping-version-two.module"
  //         ).then((module) => module.LifePracticeButtonMappingVersionTwoModule),
  //     },
  //     {
  //       path: LIFE_PRACTICE_THOUGHT_DAIRY,
  //       loadChildren: () =>
  //         import("../../life-practice-cbt/life-practice-cbt.module").then(
  //           (module) => module.LifePracticeCbtModule
  //         ),
  //     },
  //     {
  //       path: LIFE_PRACTICE_THOUGHT_CHALLENGING,
  //       loadChildren: () =>
  //         import(
  //           "../../life-practice-thought-challenging/life-practice-thought-challenging.module"
  //         ).then((module) => module.LifePracticeThoughtChallengingModule),
  //     },
  //     {
  //       path: LIFE_PRACTICE_RECOGNISE_TRIGGERS_AND_BOOSTERS,
  //       loadChildren: () =>
  //         import(
  //           "../../life-practice-choice-check/life-practice-choice-check.module"
  //         ).then((module) => module.LifePracticeChoiceCheckModule),
  //     },
  //     {
  //       path: LIFE_PRACTICE_SAYING_NO,
  //       loadChildren: () =>
  //         import(
  //           "../../life-practice-saying-no/life-practice-saying-no.module"
  //         ).then((module) => module.LifePracticeSayingNoModule),
  //     },
  //     {
  //       path: LIFE_PRACTICE_SAYING_NO_VERSION_TWO,
  //       loadChildren: () =>
  //         import(
  //           "../../life-practice-saying-no-version-two/life-practice-saying-no-version-two.module"
  //         ).then((module) => module.LifePracticeSayingNoVersionTwoModule),
  //     },
  //     {
  //       path: LIFE_PRACTICE_MOVING_FORWARD,
  //       loadChildren: () =>
  //         import(
  //           "../../life-practice-moving-forward/life-practice-moving-forward.module"
  //         ).then((module) => module.LifePracticeMovingForwardModule),
  //     },
  //     {
  //       path: LIFE_PRACTICE_MOVING_FORWARD_GROUP_ONE,
  //       loadChildren: () =>
  //         import(
  //           "../../life-practice-moving-forward-group-one/life-practice-moving-forward-group-one.module"
  //         ).then((module) => module.LifePracticeMovingForwardGroupOneModule),
  //     },
  //     {
  //       path: LIFE_PRACTICE_MOVING_FORWARD_VERSION_TWO,
  //       loadChildren: () =>
  //         import(
  //           "../../life-practice-moving-forward-version-two/life-practice-moving-forward-group-two.module"
  //         ).then((module) => module.LifePracticeMovingForwardGroupTwoModule),
  //     },
  //     {
  //       path: LIFE_PRACTICE_COGNITIVE_CRAFTING_VERSION_THREE,
  //       loadChildren: () =>
  //         import(
  //           "../../life-practice-cognitive-crafting/life-practice-cognitive-crafting.module"
  //         ).then((module) => module.LifePracticeCognitiveCraftingModule),
  //     },
  //   ],
  // },
  {
    path: `${COMPLETED_QUEST_ROUTE}/${COMPLETED_QUEST_ENTRY_PATH}`,
    loadChildren: () =>
      import(
        "../../uplift-user-quest-result/uplift-user-quest-result.module"
      ).then((m) => m.UpliftUserQuestResultModule),
  },
];

import { Component, Input, OnInit } from "@angular/core";
import { InputBlockComponent } from "../../input-block/input-block/input-block.component";
import { SmartCheckBoxBlock } from "../../types/block";
import { SessionStoreService } from "../../services/session-store.service";

@Component({
  selector: "ail-smart-check-box-block",
  template: "",
  standalone: true
})
export class SmartCheckBoxBlockComponent extends InputBlockComponent
  implements OnInit {
  @Input() declare data: SmartCheckBoxBlock;
  showCheckboxAnswers = false;

  constructor(protected _sessionStore: SessionStoreService) {
    super(_sessionStore);
  }

  viewCheckBoxAnswers() {
    this.showCheckboxAnswers = true;
    this.inputFormControl.disable()
  }

  onSelectionChanged($event: Event, blockID, position) {
    this._sessionStore.updateSmartBoxQuestData($event.target['checked'], blockID, position);
  }

}

import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { VideoBlockComponent } from "../../../../../sdk/src/lib/video-block/video-block/video-block.component";
import { Subscription } from "rxjs";
import { VideoBlockService } from "../../../../../sdk/src/lib/services/video-block.service";
import { CommonModule } from "@angular/common";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-uplift-user-video-block",
  templateUrl: "./uplift-user-video-block.component.html",
  styleUrls: ["./uplift-user-video-block.component.css"],
  standalone: true,
  imports: [CommonModule],
})
export class UpliftUserVideoBlockComponent
  extends VideoBlockComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  safeCaptionUrl: SafeResourceUrl;
  private _videoPlaySubscription$: Subscription;

  constructor(
    private _videoBlockService: VideoBlockService,
    private _domSanitizer: DomSanitizer
  ) {
    super();

    this._videoPauseSubscription$ = _videoBlockService.onPageChange$.subscribe(
      (hasPageChanged) => {
        if (hasPageChanged) {
          this._pauseVideo();
        }
      }
    );

    this._videoPlaySubscription$ = _videoBlockService.onVideoPlaying$.subscribe(
      (audioBlockID) => {
        if (audioBlockID !== this.data?.id) {
          this._pauseVideo();
        }
      }
    );
  }

  getSanitizerURL = (url: string) => {
    return this._domSanitizer.bypassSecurityTrustUrl(url);
  };

  getSanitizerCaptionURL() {
    if (this.data?.caption_url_list?.length) {
      this.safeCaptionUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(
        this.data.caption_url_list[0].source_url
      );
    }
  }

  private _pauseVideo() {
    if (
      this._videoPlayerTemplateRef !== null &&
      this._videoPlayerTemplateRef !== undefined
    ) {
      this._videoPlayerTemplateRef.pause();
    }
  }
  ngOnInit() {
    super.ngOnInit();
  }
  ngAfterViewInit() {
    this.getSanitizerCaptionURL();
  }

  ngOnDestroy(): void {
    this._videoPauseSubscription$.unsubscribe();
    this._videoPlaySubscription$.unsubscribe();
  }

  setMediaElement(videoPlayerTemplateREF: HTMLVideoElement) {
    this._videoPlayerTemplateRef = videoPlayerTemplateREF;
  }

  playVideo(videoPlayerTemplateREF: HTMLVideoElement) {
    void videoPlayerTemplateREF.play();
  }

  publishNewAudioPlay(blockID: string) {
    this._videoBlockService.onVideoPlaying$.next(blockID);
  }
}

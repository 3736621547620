import { createAction, props } from "@ngrx/store";
import { OrbitProtocol } from "../../../../sdk/src/lib/types/protocol";

export const GET_ASSIGNED_PROTOCOL_ACTION_NAME = "GET_ASSIGNED_PROTOCOL";
export const SET_ASSIGNED_PROTOCOL_ACTION_NAME = "SET_ASSIGNED_PROTOCOL";
export const FETCH_ASSIGNED_PROTOCOL_ACTION_NAME = "FETCH_ASSIGNED_PROTOCOL";

export const FETCH_ASSIGNED_PROTOCOL_ACTION = createAction(
  FETCH_ASSIGNED_PROTOCOL_ACTION_NAME,
  props<{ protocolGroupCode: string }>()
);

export const GET_ASSIGNED_PROTOCOL_ACTION = createAction(
  GET_ASSIGNED_PROTOCOL_ACTION_NAME
);

export const SET_ASSIGNED_PROTOCOL_ACTION = createAction(
  SET_ASSIGNED_PROTOCOL_ACTION_NAME,
  props<{ assignedProtocol: OrbitProtocol }>()
);

export class FetchProtocolListAction {
  static readonly type = "[Home Page] Fetch Protocol List";
  constructor() {}
}

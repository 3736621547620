// Build information, automatically generated by `ng-info`
const build = {
    version: "0.0.0",
    timestamp: "Fri May 31 2024 18:23:37 GMT+0100 (British Summer Time)",
    message: null,
    git: {
        user: "Sani Yusuf",
        branch: "uplift-user",
        hash: "f289f0",
        fullHash: "f289f0eda3bc290e5808e62785aac21365bb1813"
    }
};

export default build;
import { Component, Input } from "@angular/core";
import { AbstractBlockComponent } from "../../abstract-block/abstract-block/abstract-block.component";
import { TextBlock } from "../../types/block";

@Component({
  selector: "ail-text-block",
  template: "",
  standalone: true
})
export class TextBlockComponent extends AbstractBlockComponent {
  @Input() declare data: TextBlock;
  @Input() mode: string;

  constructor() {
    super();
  }
}

import { InjectionToken } from "@angular/core";
import { ComponentMap, ModuleConfig } from "../types/module-config";

export const AuthConfigToken = new InjectionToken<ModuleConfig>(
  "Config Constants For Configuring Authentication Module"
);

export const ComponentMapToken = new InjectionToken<ComponentMap>(
  "Component Map For Services"
);

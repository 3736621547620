import { Inject, Injectable } from "@angular/core";
import { PatientProfile } from "../types/profile";
import { from, Observable } from "rxjs";
import { BSON } from "realm-web";
import { Quest } from "../types/quest.interface";
import { RealmClientService } from "./realm-client.service";
import { Environment, ENVIRONMENT_TOKEN } from "../types/environment.token";

@Injectable({
  providedIn: "root",
})
export class TherapistService {
  constructor(
    private _realmClientService: RealmClientService,
    @Inject(ENVIRONMENT_TOKEN)
    private _environmentToken: Environment
  ) {}

  getAssignedPatientList(
    counsellorUserID: string
  ): Observable<PatientProfile[]> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.userProfileDB)
        .collection<PatientProfile>(
          this._environmentToken.userProfileCollection
        )
        .find({
          counsellor_user_id: counsellorUserID,
        })
    );
  }

  getAssignedPatientListNew(
    counsellorUserID: string,
    groupCode: number
  ): Observable<PatientProfile[]> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.userProfileDB)
        .collection<PatientProfile>(
          this._environmentToken.userProfileCollection
        )
        .find({
          counsellor_user_id: counsellorUserID,
          group_code: groupCode,
        })
    );
  }

  getPatient(patientProfileID: string): Observable<PatientProfile> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.userProfileDB)
        .collection<PatientProfile>(
          this._environmentToken.userProfileCollection
        )
        .findOne({
          _id: BSON.ObjectID.createFromHexString(patientProfileID),
        })
    );
  }

  getCompletedQuestList(patientProfileID: string): Observable<Quest[]> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection<Quest>(this._environmentToken.questResultCollection)
        .find({
          owner_user_id: patientProfileID,
        })
    );
  }

  getPatientProfile(patientProfileID: string): Observable<PatientProfile> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.userProfileDB)
        .collection<PatientProfile>(
          this._environmentToken.userProfileCollection
        )
        .findOne({
          _id: BSON.ObjectId.createFromHexString(patientProfileID),
        })
    );
  }

  getTherapistProfile(therapistID: string): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.userProfileDB)
        .collection(this._environmentToken.userProfileCollection)
        .findOne({
          owner_user_id: therapistID,
        })
    );
  }
}

<div class="block full-width rounded mb-2">
  <div
    class="block-header full-width p-3 pb-0"
    *ngIf="data?.header?.length > 0"
  >
    {{ data?.header }}
  </div>
  <div class="block-body full-width">
    <div class="p-3 video-block full-width">
      <video
        (playing)="publishNewAudioPlay(data?.id)"
        (click)="playVideo(videoPlayerTemplateREF)"
        (play)="setMediaElement(videoPlayerTemplateREF)"
        #videoPlayerTemplateREF
        class="img-fluid full-width img-thumbnail"
        [poster]="
          data?.thumbnail_image_src?.length > 0
            ? getSanitizerURL(data?.thumbnail_image_src)
            : 'assets/images/default-video-thumbnail.webp'
        "
        *ngIf="hasVideoSourceULR; else noVideoUrlTemplate"
        crossorigin="anonymous"
        controls
      >
        <source [src]="getSanitizerURL(videoSourceURL)" type="video/mp4" />

        <ng-container *ngFor="let source of data?.source_url_list">

          <source
            [src]="getSanitizerURL(source?.source_url)"
            [type]="'video/' + source?.format"
          />
        </ng-container>
        <ng-container *ngFor="let caption of data?.caption_url_list">

          <track
            [label]="caption?.language_label"
            kind="subtitles"
            [srclang]="caption?.language_code"
            [src]="safeCaptionUrl"
          />
        </ng-container>

        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</div>

<ng-template #noVideoUrlTemplate>
  <video
    class="img-fluid full-width img-thumbnail"
    [poster]="
      data?.thumbnail_image_src?.length > 0
        ? data?.thumbnail_image_src
        : 'assets/images/default-video-thumbnail.webp'
    "
    controls
  >
    Your browser does not support the video tag.
  </video>
</ng-template>

import { Component } from "@angular/core";
import { SmartRadioBlockComponent } from "../../../../../sdk/src/lib/smart-radio-block/smart-radio-block/smart-radio-block.component";
import { SessionStoreService } from "../../../../../sdk/src/lib/services/session-store.service";

@Component({
  selector: "app-uplift-user-smart-radio-block",
  templateUrl: "./uplift-user-smart-radio-block.component.html",
  styleUrls: ["./uplift-user-smart-radio-block.component.css"],
  standalone: true
})
export class UpliftUserSmartRadioBlockComponent extends SmartRadioBlockComponent {
  constructor(sessionStore: SessionStoreService) {
    super(sessionStore);
  }
}

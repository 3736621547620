export const QUEST_STATE = "questState";
export const QUEST_LIST_STATE = "questListState";
export const PROFILE_STATE = "userProfile";
export const GROUP_STATE = "groupState";
export const PROTOCOL_STATE = "protocol";
export const GROUP_LIST_STATE = "groupList";
export const PATIENT_LIST_STATE = "patientList";
export const THERAPIST_STATE = "TherapistProfile";
export const ORGANISATION_LIST_STATE = "organisationListState";
export const ORGANISATION_ADMIN_LIST_STATE = "organisationAdminListState";
export const PATIENT_IN_INSTITUTION_LIST_STATE =
  "patientInInstitutionListState";
export const THERAPIST_IN_INSTITUTION_LIST_STATE =
  "therapistInInstitutionListState";

export const PROTOCOL_LIST_STATE = "protocolListState";
export const COURSE_LIST_STATE = "courseListState";
export const NEW_PROTOCOL_STATE = "newProtocol";
export const COURSE_BEING_EDITED_STATE = "courseBeingEdited";

export const PATIENT_GROUP_LIST = "patientGroupListState";

export const JOURNAL_ITEM_ENTRY_LIST_STATE = "journalEntryListState";

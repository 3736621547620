
<div class="block full-width rounded">
  <div
    class="full-width py-4 px-4 text-center"
    *ngIf="
      data?.collapse_label &&
      data?.collapse_label !== '' &&
      isContentVisible === false
    "
  >
    <button
      type="button"
      class="btn btn-primary bg-light-primary rounded width-75-per"
      (click)="isContentVisible = !isContentVisible"
    >
      {{ data?.collapse_label }}
    </button>
    <p class="text-center font-large-2 text-primary pb-0 mb-0">...</p>
  </div>

  <ng-container *ngIf="isContentVisible === true || !data?.collapse_label">
    <div
      class="block-header full-width p-3 pb-0" [class.bg-info]="data?.type != 'html'"
      *ngIf="data?.header?.length > 0"
    >
      {{ data?.header }}
    </div>
    <div class="block-body full-width html-block-body">
      <div [innerHTML]="safeHTML" class="p-3 html-block full-width"></div>
    </div>
  </ng-container>
</div>

import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  QueryList,
  ViewChildren
} from "@angular/core";
import { CheckBoxBlockComponent } from "../../../../../sdk/src/lib/check-box-block/check-box-block/check-box-block.component";
import { SessionStoreService } from "../../../../../sdk/src/lib/services/session-store.service";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgSelectModule } from "@ng-select/ng-select";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-uplift-user-check-box-block",
  templateUrl: "./uplift-user-check-box-block.component.html",
  styleUrls: ["./uplift-user-check-box-block.component.css"],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgSelectModule]
})
export class UpliftUserCheckBoxBlockComponent extends CheckBoxBlockComponent
  implements AfterViewInit, OnDestroy {
  @ViewChildren("CheckBox") checkBoxList: QueryList<
    ElementRef<HTMLInputElement>
  >;

  multiSelectFormControl: FormControl = new FormControl();
  private _unsubScribe: Subject<boolean> = new Subject<boolean>();
  constructor(sessionStore: SessionStoreService) {
    super(sessionStore);
    if (this.isInputDisabled) {
      this._multiSelectionSubscriber();
    }
    if (this.data?.value) {
      this.multiSelectFormControl.patchValue(this.data?.value);
    }
  }

  ngAfterViewInit() {
    this.checkBoxList.forEach((checkBox, index) => {
      checkBox.nativeElement.checked = false;
      if (this.data.value && this.data.value.includes(index)) {
        checkBox.nativeElement.checked = true;
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubScribe.next(false);
    this._unsubScribe.complete();
  }

  private _multiSelectionSubscriber() {
    this.multiSelectFormControl.valueChanges
      .pipe(takeUntil(this._unsubScribe))
      .subscribe(selectedPositions => {
        this.onMultiSelectionChanged(selectedPositions, this.data?.id);
      });
  }
}

import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { AbstractBlockComponent } from "../../abstract-block/abstract-block/abstract-block.component";
import { Block, ContainerBlock } from "../../types/block";
import { BlockUtilService } from "../../services/block-util.service";
import { isNullOrUndefined } from "@swimlane/ngx-datatable";

@Component({
  selector: "ail-container-block",
  template: "",
  standalone: true
})
export class ContainerBlockComponent extends AbstractBlockComponent
  implements OnInit, OnChanges {
  @ViewChild("containerBlock", { static: true, read: ViewContainerRef })
  containerBlock: ViewContainerRef;
  @Input() declare data: ContainerBlock;

  constructor(private _blockUtilService: BlockUtilService) {
    super();
  }

  ngOnInit() {
    if (!isNullOrUndefined(this.data)) {
      this._addBlocksToContainerRef();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !isNullOrUndefined(
        changes["data"].currentValue &&
        changes["data"].currentValue !== changes["data"].previousValue
      )
    ) {
      this._addBlocksToContainerRef();
    }
  }

  private _addBlocksToContainerRef() {
    const sortedBlockList: Block[] = this._blockUtilService.sortBlockList(
      this.data.blocks
    );
    this._blockUtilService.addBlocksToContainerRef(
      this.containerBlock,
      sortedBlockList
    );
  }
}

import { Component, OnDestroy } from "@angular/core";
import { AudioBlockComponent } from "../../../../../sdk/src/lib/audio-block/audio-block/audio-block.component";
import { DomSanitizer } from "@angular/platform-browser";
import { AudioBlockService } from "../../../../../sdk/src/lib/services/audio-block.service";
import { Subscription } from "rxjs";
import { VgCoreModule } from "@videogular/ngx-videogular/core";
import { VgControlsModule } from "@videogular/ngx-videogular/controls";
import { VgOverlayPlayModule } from "@videogular/ngx-videogular/overlay-play";
import { VgBufferingModule } from "@videogular/ngx-videogular/buffering";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-uplift-user-audio-block",
  templateUrl: "./uplift-user-audio-block.component.html",
  styleUrls: ["./uplift-user-audio-block.component.css"],
  standalone: true,
  imports: [
    CommonModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
  ],
})
export class UpliftUserAudioBlockComponent
  extends AudioBlockComponent
  implements OnDestroy
{
  private _audioPauseSubscription: Subscription;
  private _audioPlaySubscription: Subscription;

  constructor(
    protected _domSanitizer: DomSanitizer,
    protected _audioBlockService: AudioBlockService
  ) {
    super(_domSanitizer);

    this._audioPauseSubscription = _audioBlockService.onPageChange$.subscribe(
      (hasPageChanged) => {
        if (hasPageChanged) {
          this._pauseAudio();
        }
      }
    );
    this._audioPlaySubscription = _audioBlockService.onAudioPlaying$.subscribe(
      (audioBlockID) => {
        if (audioBlockID !== this.data?.id) {
          this._pauseAudio();
        }
      }
    );
  }

  private _pauseAudio() {
    if (
      this._audioTemplateRef !== null &&
      this._audioTemplateRef !== undefined
    ) {
      this._audioTemplateRef.element.nativeElement.pause();
    }
  }

  ngOnDestroy(): void {
    this._audioPauseSubscription.unsubscribe();
    this._audioPlaySubscription.unsubscribe();
  }

  publishNewAudioPlay(blockID: string) {
    this._audioBlockService.onAudioPlaying$.next(blockID);
  }
}

import {Injectable} from "@angular/core";
import {Action, NgxsAfterBootstrap, Selector, State, StateContext, Store,} from "@ngxs/store";
import {Quest} from "../../../../sdk/src/lib/types/quest.interface";
import {QUEST_STATE} from "../../../../../state-name-list/state-names";
import {
  FetchCompletedQuestAction,
  FetchQuestBeingExecutedAction,
  FetchQuestListInProtocolAction,
  SetQuestListInProtocolAction,
  UpdateProgressBarPageAction,
  UpdateProgressBarVisibilityAction,
  UpdateQuestBeingExecutedAction,
} from "../actions/quest.actions";
import {QuestService} from "../../../../sdk/src/lib/services/quest.service";
import {switchMap, tap} from "rxjs/operators";
import {RealmClientService} from "../../../../sdk/src/lib/services/realm-client.service";
import {LifePractice} from "../../../../sdk/src/lib/types/life-practice";

@State<QuestStateModel>({
  name: QUEST_STATE,
  defaults: {
    completedQuestList: [],
    questInProtocolList: [],
    questBeingExecuted: null,
    isProgressBarVisible: false,
    currentQuestProgressBarPage: 0,
  },
})
@Injectable({
  providedIn: "root",
})
export class QuestState implements NgxsAfterBootstrap {

  constructor(
    private _store: Store,
    private _questService: QuestService,
    private _realmClientService: RealmClientService
  ) {
  }

  @Selector()
  static completedQuestList(state: QuestStateModel): Array<Quest> {
    return state.completedQuestList;
  }

  @Selector()
  static questInProtocolList(state: QuestStateModel): Array<Quest> {
    return state.questInProtocolList;
  }

  @Selector()
  static questBeingEdited(state: QuestStateModel)  {
    return state.questBeingExecuted;
  }

  @Selector()
  static isProgressBarVisible(state: QuestStateModel): boolean {
    return state.isProgressBarVisible;
  }

  @Selector()
  static currentQuestProgressBarPage(state: QuestStateModel): number {
    return state.currentQuestProgressBarPage;
  }

  ngxsAfterBootstrap() {
    if (
      this._realmClientService.realmClient?.currentUser?.isLoggedIn === true
    ) {
      this._store.dispatch([new FetchCompletedQuestAction()]);
    }
  }

  @Action(UpdateProgressBarPageAction)
  updateProgressBarPage(
    stateContext: StateContext<QuestStateModel>,
    updateProgressBarPageAction: UpdateProgressBarPageAction
  ) {
    stateContext.patchState({
      currentQuestProgressBarPage: updateProgressBarPageAction.pageNumber,
    });
  }

  @Action(UpdateProgressBarVisibilityAction)
  updateProgressBarVisibility(
    stateContext: StateContext<QuestStateModel>,
    updateProgressBarVisibilityAction: UpdateProgressBarVisibilityAction
  ) {
    stateContext.patchState({
      isProgressBarVisible:
      updateProgressBarVisibilityAction.isProgressBarVisible,
    });
  }

  @Action(FetchQuestBeingExecutedAction)
  setQuestBeingExecuted(
    stateContext: StateContext<QuestStateModel>,
    questBeingExecutedAction: FetchQuestBeingExecutedAction
  ) {
    return this._store
      .select<Array<Quest>>((store) => store[QUEST_STATE].questInProtocolList)
      .pipe(
        switchMap((questList) => {
          const QUEST = questList.find(
            (quest) => quest.readable_id === questBeingExecutedAction.questID
          );

          return this._store.dispatch(
            new UpdateQuestBeingExecutedAction(QUEST)
          );
        })
      );
  }

  @Action(UpdateQuestBeingExecutedAction)
  updateQuestBeingExecuted(
    stateContext: StateContext<QuestStateModel>,
    questBeingExecutedAction: UpdateQuestBeingExecutedAction
  ) {
    stateContext.patchState({
      questBeingExecuted: {...questBeingExecutedAction.quest},
    });
  }

  @Action(FetchQuestListInProtocolAction)
  fetchQuestListInProtocol(
    stateContext: StateContext<QuestStateModel>,
    fetchQuestListInProtocolAction: FetchQuestListInProtocolAction
  ) {
    return this._questService
      .getQuestsFromQuestObjectID_List(
        fetchQuestListInProtocolAction.questIdList
      )
      .pipe(
        tap((questList) => {
          stateContext.patchState({
            questInProtocolList: [...questList],
          });
        })
      );
  }

  @Action(SetQuestListInProtocolAction)
  setQuestListInProtocol(
    stateContext: StateContext<QuestStateModel>,
    setQuestListInProtocolAction: SetQuestListInProtocolAction
  ) {
    stateContext.patchState({
      questInProtocolList: [...setQuestListInProtocolAction.questList],
    });
  }

  @Action(FetchCompletedQuestAction)
  fetchCompletedQuestList(stateContext: StateContext<QuestStateModel>) {
    return this._questService
      .getQuestCompletedByPatient(
        this._realmClientService.realmClient.currentUser.id
      )
      .pipe(
        tap((completedQuestList) => {
          stateContext.patchState({
            completedQuestList: [...completedQuestList],
          });
        })
      );
  }
}

export interface QuestStateModel {
  completedQuestList: Array<Quest>;
  questInProtocolList: Array<Quest>;
  questBeingExecuted: Quest | LifePractice;
  isProgressBarVisible: boolean;
  currentQuestProgressBarPage: number;
}

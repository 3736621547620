<div class="block full-width rounded">
  <div class="block-header full-width p-3" *ngIf="data?.header?.length > 0">
    {{ data?.header }}
  </div>
  <div class="block-body full-width">
    <div class="p-3 mb-0 radio-block full-width form-group">
      <form
        [formGroup]="inputFormControl"
        *ngIf="inputFormControl"
        class="form"
      >
        <ng-container *ngFor="let radioButton of data.options; index as i">
          <div *ngIf="isInputDisabled" class="custom-radio-button">
            <input
              #RadioButton
              [id]="data.id + radioButton.position.toString()"
              [value]="radioButton.position"
              [attr.name]="data.id"
              [formControlName]="data.id"
              readonly
              type="radio"
            />
            <label [attr.for]="data?.id + data?.position.toString()">{{
              radioButton.label
            }}</label>
          </div>

          <div *ngIf="!isInputDisabled" class="custom-radio-button">
            <input
              #RadioButton
              [id]="data.id + radioButton.position.toString()"
              [attr.name]="data.id"
              [value]="radioButton.position"
              [checked]="
                radioButton.position.toString() ===
                inputFormControl.get(data?.id).value
              "
              (change)="onSelectionChange(radioButton.position)"
              [formControlName]="data.id"
              type="radio"
            />
            <label [attr.for]="data?.id + radioButton?.position.toString()">{{
              radioButton.label
            }}</label>
          </div>
        </ng-container>

        <div
          *ngIf="
            data?.config?.other_field !== undefined &&
            data?.config?.other_field?.selection_field_text
          "
          class="custom-radio-button"
        >
          <input
            #RadioButton
            [formControlName]="data.id"
            [id]="data.id + data.options.length.toString()"
            [checked]="data.value === data.options.length"
            [value]="data.options.length"
            [attr.name]="data.id"
            [readonly]="isInputDisabled"
            type="radio"
            (change)="onSelectionChange(data.options.length)"
          />
          <label
            [id]="data?.id + data?.options.length.toString() + '-label'"
            [attr.for]="data?.id + data?.options.length.toString()"
          >
            <ng-container
              *ngIf="data?.config?.other_field?.selection_field_text === ''"
            >
              {{ data?.config?.other_field?.selection_field_text }}
            </ng-container>

            <ng-container
              *ngIf="data?.config?.other_field?.selection_field_text !== ''"
            >
              {{ data?.config?.other_field?.selection_field_text }}
            </ng-container>
          </label>
        </div>

        <ng-container *ngIf="isOtherFieldSelected(data)">
          <fieldset class="form-group">
            <label
              [id]="data?.id + 'other-field-label'"
              [for]="data?.id + 'other-field-input'"
              >{{ data?.config?.other_field.label || "" }}</label
            >
            <input
              *ngIf="mode === 'locked'"
              [value]="data?.config?.other_field?.body || ''"
              readonly
              type="text"
              class="form-control"
              [id]="data?.id + 'other-field-input'"
              [placeholder]="data?.config?.other_field.placeholder || ''"
            />

            <input
              *ngIf="mode !== 'locked'"
              [formControl]="inputFormControl?.get('OTHER_FIELD')"
              (input)="
                onOtherFieldChange($event); onOtherFieldValueChanges($event)
              "
              [value]="data?.config?.other_field?.body || ''"
              [readonly]="isInputDisabled"
              type="text"
              class="form-control"
              [id]="data?.id + 'other-field-input'"
              [placeholder]="data?.config?.other_field.placeholder || ''"
            />
          </fieldset>
        </ng-container>
      </form>
    </div>
  </div>
</div>

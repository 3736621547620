import {Injectable} from "@angular/core";
import {Action, NgxsAfterBootstrap, State, StateContext, Store,} from "@ngxs/store";
import {PROFILE_STATE} from "../../../../../state-name-list/state-names";
import {map, switchMap, tap} from "rxjs/operators";
import {PatientProfile} from "../../../../sdk/src/lib/types/profile";
import {FetchPatientProfileAction} from "../actions/patient.actions";
import {RealmClientService} from "../../../../sdk/src/lib/services/realm-client.service";
import {PatientService} from "../../../../sdk/src/lib/services/patient.service";
import {OrganisationService} from "../../../../sdk/src/lib/services/organisation.service";
import {combineLatest} from "rxjs";
import {TherapistService} from "../../../../sdk/src/lib/services/therapist.service";

@State<PatientProfile>({
  name: PROFILE_STATE,
  defaults: null,
})
@Injectable({
  providedIn: "root",
})
export class ProfileState implements NgxsAfterBootstrap {

  constructor(
    private _patientService: PatientService,
    private _realmClientService: RealmClientService,
    private _store: Store,
    private _organizationService: OrganisationService,
    private _therapistService: TherapistService
  ) {
  }

  ngxsAfterBootstrap() {
    if (this._realmClientService.realmClient?.currentUser?.isLoggedIn) {
      this._store.dispatch([new FetchPatientProfileAction()]);
    }
  }

  @Action(FetchPatientProfileAction)
  fetchPatientProfile(stateContext: StateContext<PatientProfile>) {
    return combineLatest([
      this._patientService.getPatientProfileByUserID(this._realmClientService.realmClient?.currentUser?.id),
      this._organizationService.getOrganisationList()
    ]).pipe(
      switchMap(([patientProfile, organizationList]) => {
        const ORGANIZATION_OBJECT = organizationList.find(organization => organization._id.toString() === patientProfile.assigned_organisation_id.toString());

        return this._therapistService.getTherapistProfile(patientProfile.counsellor_user_id?.toString()).pipe(
          map(therapistProfile => ({
            ...patientProfile,
            organization: ORGANIZATION_OBJECT,
            therapist: therapistProfile
          }))
        );
      }),
      tap(updatedProfile => {
        stateContext.patchState({
          ...updatedProfile
        });
      })
    );
  }
}

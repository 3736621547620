import { Component, Input, OnInit } from "@angular/core";
import { InputBlockComponent } from "../../input-block/input-block/input-block.component";
import { SmartRadioBlock } from "../../types/block";
import { SessionStoreService } from "../../services/session-store.service";
import { Subscription } from "rxjs";

@Component({
  selector: "ail-smart-radio-block",
  template: "",
  standalone: true
})
export class SmartRadioBlockComponent extends InputBlockComponent
  implements OnInit {
  @Input() declare data: SmartRadioBlock;
  selectionCountUsed: number = 0;
  showAllAnswers = false;
  inputFormControlSubscription: Subscription;

  constructor(sessionStore: SessionStoreService) {
    super(sessionStore);
  }

  ngOnInit() {
    super.ngOnInit();
    this.inputFormControlSubscription = this.inputFormControl.valueChanges.subscribe(
      () => {
        this.validateSelection();
      }
    );
  }

  validateSelection() {
    this.selectionCountUsed += 1;
    if (
      !this.data.config.selection_count ||
      this.data.config.selection_count === this.selectionCountUsed
    ) {
      this.showAllAnswers = true;
      this.inputFormControl.disable({
        onlySelf: true
      });
    }
  }
}

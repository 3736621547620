import {inject, Injectable} from "@angular/core";
import {Action, NgxsAfterBootstrap, State, StateContext, Store,} from "@ngxs/store";
import {GROUP_STATE} from "../../../../../state-name-list/state-names";
import {map, switchMap, tap} from "rxjs/operators";
import {FetchGroupAction} from "../actions/group.actions";
import {Group} from "projects/sdk/src/lib/types/group";
import {RealmClientService} from "../../../../sdk/src/lib/services/realm-client.service";
import {ProfileState} from "./profile.state";
import {BSON} from "realm-web";
import {Quest} from "../../../../sdk/src/lib/types/quest.interface";
import {QuestService} from "../../../../sdk/src/lib/services/quest.service";
import {ProtocolService} from "../../../../sdk/src/lib/services/protocol.service";
import {Observable} from "rxjs";
import {PatientProfile} from "../../../../sdk/src/lib/types/profile";

@State<Group>({
  name: GROUP_STATE,
  defaults: null,
})
@Injectable({
  providedIn: "root",
})
export class GroupState implements NgxsAfterBootstrap {

  private _profileState$: Observable<PatientProfile> = inject(Store).select(ProfileState)

  constructor(
    private _store: Store,
    private _questService: QuestService,
    private _protocolService: ProtocolService,
    private _realmClientService: RealmClientService
  ) {
  }

  ngxsAfterBootstrap() {
    if (this._realmClientService.realmClient?.currentUser?.isLoggedIn === true) {
      this._store.dispatch([new FetchGroupAction()]);
    }
  }

  @Action(FetchGroupAction)
  fetchGroupAction(stateContext: StateContext<Group>) {
    return this._profileState$.pipe(
      switchMap((patientProfile) => {
        return this._protocolService.getGroupByGroupId(
          new BSON.ObjectId(patientProfile?.assigned_group_id)
        );
      }),
      switchMap((group) => {
        const MODULE_IN_COURSE_ID_LIST = [];
        group?.course.session_list.forEach((session) => {
          session.session_module_item_list.forEach((moduleItem) => {
            if (!moduleItem.is_webinar) {
              MODULE_IN_COURSE_ID_LIST.push(moduleItem.module_item_id);
            }
          });
        });

        group?.course.course_menu_item_list.forEach((courseMenuItem) => {
          courseMenuItem.menu_item_session_list.forEach((session) => {
            session.session_module_item_list.forEach((moduleItem) => {
              if (!moduleItem.is_webinar) {
                MODULE_IN_COURSE_ID_LIST.push(moduleItem.module_item_id);
              }
            });
          });
        });

        const UNIQUE_MODULE_IN_COURSE_ID_LIST = [
          ...new Set(MODULE_IN_COURSE_ID_LIST),
        ];
        const UNIQUE_MODULE_IN_COURSE_OBJECT_ID_LIST =
          UNIQUE_MODULE_IN_COURSE_ID_LIST.map(
            (objectID) => new BSON.ObjectId(objectID)
          );

        return this._questService
          .getModuleListFromModuleObjectIdList(
            UNIQUE_MODULE_IN_COURSE_OBJECT_ID_LIST
          )
          .pipe(
            map((moduleList) => {
              if (!group) {
                return null;
              }
              const MODULES_IN_COURSE_RECORD: Record<string, Quest> = {};
              moduleList.forEach((module) => {
                MODULES_IN_COURSE_RECORD[module._id.toString()] = module;
              });

              const GROUP_WITH_MODULE_ITEM: Group = {...group};
              GROUP_WITH_MODULE_ITEM.course.session_list =
                this._addModuleItemToCourSessionList(
                  GROUP_WITH_MODULE_ITEM,
                  MODULES_IN_COURSE_RECORD
                );

              GROUP_WITH_MODULE_ITEM.course.course_menu_item_list =
                this._addModuleItemToCourMenuItemList(
                  GROUP_WITH_MODULE_ITEM,
                  MODULES_IN_COURSE_RECORD
                );

              return GROUP_WITH_MODULE_ITEM;
            })
          );
      }),
      tap((groupWithModuleItem) => {
        stateContext.setState({...groupWithModuleItem});
      })
    );
  }

  private _addModuleItemToCourMenuItemList(
    GROUP_WITH_MODULE_ITEM: Group,
    MODULES_IN_COURSE_RECORD: Record<string, Quest>
  ) {
    return GROUP_WITH_MODULE_ITEM?.course?.course_menu_item_list.map(
      (courseMenuItem) => {
        return {
          ...courseMenuItem,
          menu_item_session_list: courseMenuItem.menu_item_session_list.map(
            (session) => {
              return {
                ...session,
                session_module_item_list: session.session_module_item_list.map(
                  (moduleItem) => {
                    const MODULE_ITEM = {...moduleItem};
                    if (!moduleItem.is_webinar) {
                      MODULE_ITEM.module_item =
                        MODULES_IN_COURSE_RECORD[moduleItem.module_item_id];
                    }

                    return {
                      ...MODULE_ITEM,
                    };
                  }
                ),
              };
            }
          ),
        };
      }
    );
  }

  private _addModuleItemToCourSessionList(
    GROUP_WITH_MODULE_ITEM: Group,
    MODULES_IN_COURSE_RECORD: Record<string, Quest>
  ) {
    return GROUP_WITH_MODULE_ITEM?.course?.session_list.map((session) => {
      return {
        ...session,
        session_module_item_list: session.session_module_item_list.map(
          (moduleItem) => {
            const MODULE_ITEM = {...moduleItem};
            if (!moduleItem.is_webinar) {
              MODULE_ITEM.module_item =
                MODULES_IN_COURSE_RECORD[moduleItem.module_item_id];
            }

            return {
              ...MODULE_ITEM,
            };
          }
        ),
      };
    });
  }
}

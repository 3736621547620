import { Component, Input } from "@angular/core";
import { AbstractBlockComponent } from "../../abstract-block/abstract-block/abstract-block.component";
import { ImageBlock } from "../../types/block";

@Component({
  selector: "ail-image-block",
  template: ""
})
export class ImageBlockComponent extends AbstractBlockComponent {
  @Input() declare data: ImageBlock;
  @Input() mode: string;
}

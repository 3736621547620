import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AudioBlockService {
  onAudioPlaying$: BehaviorSubject<string> = new BehaviorSubject<string>("");

  onPageChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  questPageChanged() {
    this.onPageChange$.next(true);
  }
}

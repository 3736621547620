import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  LOGIN_PATH,
  PASSWORD_CHANGE,
} from "../../../../sdk/src/lib/values/shared-paths";
import { RealmClientService } from "../../../../sdk/src/lib/services/realm-client.service";
import { PatientService } from "../../../../sdk/src/lib/services/patient.service";

@Injectable({
  providedIn: "root",
})
export class HasChangedPasswordGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _patientService: PatientService,
    private _realmClientService: RealmClientService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      this._realmClientService.realmClient?.currentUser?.isLoggedIn === false
    ) {
      void this._router.navigate([`/${LOGIN_PATH}`]);
    }

    return this._patientService
      .getPatientProfileByUserID(
        this._realmClientService.realmClient?.currentUser?.id
      )
      .pipe(
        map((patientProfile) => {
          if (
            patientProfile &&
            patientProfile.has_changed_initial_password === false
          ) {
            void this._router.navigate([`/${PASSWORD_CHANGE}`]);
          } else {
            return true;
          }
        })
      );
  }
}

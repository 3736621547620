<!--Footer Starts-->
<footer class="footer">
  <p class="copyright clearfix text-muted m-0">
    <a
      id="pixinventLink"
      href="https://themeforest.net/user/pixinvent/portfolio?ref=pixinvent"
    >Mindlife
    </a>
    <span class="d-none d-sm-inline-block"
    >, All rights reserved. Build : <b>{{ buildNumber }}</b></span
    >
  </p>
</footer>
<!--Footer Ends-->

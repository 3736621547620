import { Injectable } from "@angular/core";
import {
  State,
  NgxsAfterBootstrap,
  StateContext,
  Action,
  Store,
} from "@ngxs/store";
import { PROTOCOL_STATE } from "../../../../../state-name-list/state-names";
import { SetQuestListInProtocolAction } from "../actions/quest.actions";
import { map, switchMap, tap } from "rxjs/operators";
import { ProtocolService } from "../../../../sdk/src/lib/services/protocol.service";
import {
  OrbitProtocol,
  Protocol,
} from "../../../../sdk/src/lib/types/protocol";
import * as Realm from "realm-web";
import { QuestService } from "../../../../sdk/src/lib/services/quest.service";
import { FetchProtocolListAction } from "../actions/protocol.actions";
import { RealmClientService } from "../../../../sdk/src/lib/services/realm-client.service";

@State<Protocol>({
  name: PROTOCOL_STATE,
  defaults: null,
})
@Injectable({
  providedIn: "root",
})
export class ProtocolState implements NgxsAfterBootstrap {
  constructor(
    private _store: Store,
    private _questService: QuestService,
    private _protocolService: ProtocolService,
    private _realmClientService: RealmClientService,
  ) {}

  ngxsAfterBootstrap() {
    if (
      this._realmClientService.realmClient?.currentUser?.isLoggedIn === true
    ) {
      this._store.dispatch([new FetchProtocolListAction()]);
    }
  }

  @Action(FetchProtocolListAction)
  fetchProtocolList(stateContext: StateContext<Protocol>) {
    return this._protocolService
      .getPatientProtocolByUserID(
        this._realmClientService.realmClient.currentUser.id,
      )
      .pipe(
        switchMap((patientProtocol) => {
          return this._protocolService.getProtocolListOfGroupCode(
            patientProtocol?.group_code,
          );
        }),
        switchMap((protocol) => {
          const PROTOCOL: any = {
            ...protocol,
          };

          const QUEST_ID_LIST = [];

          (protocol as OrbitProtocol).protocol_list.forEach((protocolWeek) => {
            protocolWeek.quest_order_list.forEach((questInProtocol) => {
              QUEST_ID_LIST.push(
                Realm.BSON.ObjectID.createFromHexString(
                  questInProtocol.quest_id,
                ),
              );
            });
          });

          return this._questService
            .getQuestsFromQuestObjectID_List(QUEST_ID_LIST)
            .pipe(
              map((questList) => {
                this._store.dispatch(
                  new SetQuestListInProtocolAction(questList),
                );

                PROTOCOL.protocol_list = (
                  PROTOCOL as OrbitProtocol
                ).protocol_list.map((protocolWeek) => {
                  return {
                    ...protocolWeek,
                    quest_order_list: protocolWeek.quest_order_list.map(
                      (protocolQuest) => {
                        return {
                          ...protocolQuest,
                          quest: questList.find(
                            (quest) =>
                              quest._id.toString() === protocolQuest.quest_id,
                          ),
                        };
                      },
                    ),
                  };
                });

                return PROTOCOL;
              }),
            );
        }),
        tap((protocol) => {
          stateContext.setState(protocol);
        }),
      );
  }
}

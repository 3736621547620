import { Component } from "@angular/core";
import { ImageBlockComponent } from "../../../../../sdk/src/lib/image-block/image-block/image-block.component";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-uplift-user-image-block",
  templateUrl: "./uplift-user-image-block.component.html",
  styleUrls: ["./uplift-user-image-block.component.css"],
  standalone: true,
  imports: [CommonModule]
})
export class UpliftUserImageBlockComponent extends ImageBlockComponent {
  constructor() {
    super();
  }
}

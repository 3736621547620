import { Component, Input, OnInit } from "@angular/core";
import { InputBlockComponent } from "../../input-block/input-block/input-block.component";
import { CheckBoxBlock } from "../../types/block";
import { SessionStoreService } from "../../services/session-store.service";

@Component({
  selector: "ail-check-box-block",
  template: "",
  standalone: true
})
export class CheckBoxBlockComponent extends InputBlockComponent
  implements OnInit {
  @Input() declare data: CheckBoxBlock;

  constructor(sessionStore: SessionStoreService) {
    super(sessionStore);
  }

  onSelectionChanged($event: Event, blockID, position) {
    this._sessionStore.updateSmartBoxQuestData(
      $event.target["checked"],
      blockID,
      position
    );
  }

  onMultiSelectionChanged(selectedPositionValues: number[], blockID) {
    this._sessionStore.updateSmartBoxQuestDataForMultiSelect(
      selectedPositionValues,
      blockID
    );
  }
}

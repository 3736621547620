<div class="block full-width rounded">
  <div
    class="block-header full-width p-3 pb-0"
    *ngIf="data?.header?.length > 0"
  >
    {{ data?.header }}
  </div>
  <div class="block-body full-width">
    <div
      *ngIf="data?.source_url?.length > 0"
      class="image-block full-width rounded"
      [style.background-image]="'url(' + data.source_url + ')'"
    ></div>
  </div>
</div>

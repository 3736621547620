import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { RealmClientService } from "../../../../sdk/src/lib/services/realm-client.service";
import { HOME_PATH } from "../../../../sdk/src/lib/values/shared-paths";

@Injectable({
  providedIn: "root",
})
export class CanSeeLogInPageGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _realmClientService: RealmClientService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this._realmClientService?.realmClient?.currentUser?.isLoggedIn) {
      void this._router.navigate([`/${HOME_PATH}`]);
      return false;
    } else {
      return true;
    }
  }
}

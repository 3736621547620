export const CALCULATION_PATH = "calculation";
export const HOME_PATH = "home";
export const CHANGE_INITIAL_PASSWORD_PATH = "change-initial-password";
export const CONFIRM_ORGANISATION_PATH = "confirm-organisation-admin-status";
export const PATIENT_PROFILE_ID_ROUTE_PARAM = "patientProfileID";
export const PATIENT_USER_ID_ROUTE_PARAM = "patientUserID";
export const PROTOCOL_DETAIL_ROUTE_PARAM = "protocolID";
export const COURSE_DETAIL_ROUTE_PARAM = "courseID";
export const PROTOCOL_DETAIL_HISTORY_PATH = "history";
export const COMPLETED_QUEST_ROUTE = "completed-quests";
export const PROTOCOL_LIST_ROUTE = `protocols`;
export const PROTOCOL_ROUTE = `protocol`;
export const CREATE_PROTOCOL_ROUTE = `create`;
export const EDIT_COURSE_ROUTE = `edit-course`;
export const EDIT_PROTOCOL_ROUTE = `edit`;
export const COUNCILLOR_PATH = "councillor";
export const COUNCILLOR_PARAM_PATH = "councillorID";
export const LIFE_PRACTICE_LIST_ROUTE = "life-practices";
export const LIFE_PRACTICE_DETAIL_COMPLETED_ROUTE = "detail";
export const LIFE_PRACTICE_CREATE_ROUTE = "create";
export const LIFE_PRACTICE_UPDATE_ROUTE = "update";
export const LIFE_PRACTICE_DETAIL_ROUTE_PARAM = "lifePracticeID";
export const LIFE_PRACTICE_ROUTE = "life-practice";
export const LIFE_PRACTICE_BUILT_IN_PATH = "built-in";
export const LIFE_PRACTICE_CUSTOM_PATH = "custom";
export const PATIENT_DETAIL_PATH = "patient";
export const LOGOUT_PATH = "logout";
export const LOGIN_PATH = "login";
export const CONFIRM_ACCEPTANCE_PATH = "confirm-organisation";
export const LOGIN_REDIRECT_PATH = "login-redirect";
export const QUEST_PATH = "quest";
export const QUEST_LIST_PATH = "quests";
export const COMPLETED_QUEST_PATH_PARAM = "completedQuestID";
export const QUEST_READABLE_ID_PATH_PARAM = "questReadableID";
export const QUEST_RESULT_PATH = "result";
export const PASSWORD_RECOVERY = "recover-password";
export const PASSWORD_CHANGE = "change-password";
export const PASSWORD_RESET_CHANGE = "password-reset-password-change";
export const PASSWORD_RESET_CONFIRMATION = "password-reset-confirmation";
export const COMPLETED_QUEST_ENTRY_PATH = "completed";
export const CONFIRM_PARTICIPANT = "confirm-participant";
export const PATIENT_EDIT_PATH = "edit";
export const UPDATE_PROFILE = "update-profile";
export const QUEST_BLOCK_PATH = "quest-block";
export const PATIENT_PROGRESS = "patient-progress";
export const CREATE_LP_ENTRY = "create";
export const GROUP_LIST_PATH = "groups";
export const GROUP_PATH = "group";
export const GROUP_ROUTE_PARAM = "groupID";
export const WEBINAR_LIST_PATH = "webinars";
export const WEBINAR_PATH = "webinar";
export const WEBINAR_ROUTE_PARAM = "webinarID";
export const THERAPY_SESSION_PATH = "therapy-session";
export const THERAPIST_SESSION_PATH = "therapist-session";
export const MY_JOURNALS_PATH = "my-journals";
export const LIFE_SKILLS_PATH = "life-skills";
export const MY_GOALS_PATH = "my-goals";
export const LEARNING_LIBRARY_PATH = "learning-library";
export const HELP_PATH = "help";
export const THERAPY_FAQ_PATH = "therapy-faq";
export const EMERGENCY_HELP_PATH = "emergency-help";
export const MY_ORGANISATION_PATH = "organisation";
export const THERAPIST_LIST_PATH = "therapists";
export const ORGANISATION_ADMIN_LIST_PATH = "organisation-administrators";
export const DASHBOARD_PATH = "dashboard";

export const PROTOCOL_LIST_PATH = "protocols";

export const ORGANISATION_LIST_PATH = "organisations";

export const PATIENT_LIST_PATH = "patients";

export const EDITOR_PATH = "editor";

export const QUEST_ROUTE_PARAM = "questID";

export const COURSE_ROUTE_PARAM = "courseID";

export const COURSE_MENU_ITEM_ROUTE = "course-menu-item";
export const LIFE_PRACTICE_LIST_LP_ROUTE = "list-life-practice";

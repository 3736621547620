import { from, Observable } from "rxjs";
import { PatientProfile } from "../types/profile";
import { map } from "rxjs/operators";
import { RealmClientService } from "./realm-client.service";
import { Inject, Injectable } from "@angular/core";
import { Environment, ENVIRONMENT_TOKEN } from "../types/environment.token";

@Injectable({
  providedIn: "root",
})
export class PatientService {
  constructor(
    private _realmClientService: RealmClientService,
    @Inject(ENVIRONMENT_TOKEN) private _environmentToken: Environment
  ) {}

  enrolPatient(patient: PatientProfile) {
    return from(
      this._realmClientService.realmClient.currentUser.callFunction(
        this._environmentToken.enrolSinglePatientFunction,
        patient
      )
    );
  }

  checkIfPatientExists(email: string) {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.userProfileDB)
        .collection<PatientProfile>(
          this._environmentToken.userProfileCollection
        )
        .count({
          email: email,
        })
    ).pipe(
      map((count) => {
        return count !== 0;
      })
    );
  }

  checkIfPatientInListExist(loginUserIdList: Array<string>) {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.userProfileDB)
        .collection<PatientProfile>(
          this._environmentToken.userProfileCollection
        )
        .count({
          user_login_id: {
            $in: [...loginUserIdList],
          },
        })
    ).pipe(
      map((count) => {
        return count !== 0;
      })
    );
  }

  getPatientProfileByUserID(patientUserID: string): Observable<PatientProfile> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.userProfileDB)
        .collection<PatientProfile>(
          this._environmentToken.userProfileCollection
        )
        .findOne({
          owner_user_id: patientUserID,
        })
    );
  }

  getPatientAssignedToTherapistCount(
    therapistUserID: string
  ): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.userProfileDB)
        .collection<PatientProfile>(
          this._environmentToken.userProfileCollection
        )
        .count({
          counsellor_user_id: therapistUserID,
        })
    );
  }

  updatePatentProfile(patientUserID: string, patientProfilePartial: object) {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.userProfileDB)
        .collection<PatientProfile>(
          this._environmentToken.userProfileCollection
        )
        .updateOne(
          {
            owner_user_id: patientUserID,
          },
          {
            $set: patientProfilePartial,
          }
        )
    );
  }

  getPatientsInGroup(groupCode: string): Observable<Array<PatientProfile>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.userProfileDB)
        .collection<PatientProfile>(
          this._environmentToken.userProfileCollection
        )
        .find({
          group_code: groupCode,
        })
    );
  }

  getAllPatientList(): Observable<Array<PatientProfile>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.userProfileDB)
        .collection<PatientProfile>(
          this._environmentToken.userProfileCollection
        )
        .find({})
    );
  }

  getPatientAssignedToCounsellorList(
    counsellorUserId: string
  ): Observable<Array<PatientProfile>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.userProfileDB)
        .collection<PatientProfile>(
          this._environmentToken.userProfileCollection
        )
        .find({
          counsellor_user_id: counsellorUserId,
        })
    );
  }

  getPatientAssignedToGroupList(
    groupID: string
  ): Observable<Array<PatientProfile>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.userProfileDB)
        .collection<PatientProfile>(
          this._environmentToken.userProfileCollection
        )
        .find({
          assigned_group_id: groupID,
        })
    );
  }
}

import { Routes } from "@angular/router";
import {
  COMPLETED_QUEST_ROUTE,
  COURSE_MENU_ITEM_ROUTE,
  COURSE_ROUTE_PARAM,
  HOME_PATH,
  LIFE_PRACTICE_ROUTE,
  MY_JOURNALS_PATH,
  THERAPIST_SESSION_PATH, UPDATE_PROFILE,
} from "../../../../../sdk/src/lib/values/shared-paths";

export const Full_ROUTES: Routes = [
  {
    path: HOME_PATH,
    loadChildren: () =>
      import("../../home/home.module").then((m) => m.HomeModule),
  },
  {
    path: LIFE_PRACTICE_ROUTE,
    loadChildren: () =>
      import("../../life-practice/life-practice.module").then(
        (module) => module.LifePracticeModule
      ),
  },
  {
    path: COMPLETED_QUEST_ROUTE,
    loadChildren: () =>
      import("../../completed-quests/completed-quests.module").then(
        (m) => m.CompletedQuestsModule
      ),
  },
  {
    path: MY_JOURNALS_PATH,
    loadComponent: () =>
      import("../../journal/journal.component").then(
        (journalComponent) => journalComponent.JournalComponent
      ),
  },
  {
    path: THERAPIST_SESSION_PATH,
    loadComponent: () =>
      import("../../therapist-session/therapist-session.component").then(
        (therapistSessionComponent) =>
          therapistSessionComponent.TherapistSessionComponent
      ),
  },
  {
    path: `${COURSE_MENU_ITEM_ROUTE}/:${COURSE_ROUTE_PARAM}`,
    loadComponent: () =>
      import(
        "../../course-menu-item-view/course-menu-item-view.component"
      ).then(
        (courseMenuItemComponent) =>
          courseMenuItemComponent.CourseMenuItemViewComponent
      ),
  },
  {
    path: `${UPDATE_PROFILE}`,
    loadComponent: () =>
      import(
        "../../edit-patient-profile/edit-patient-profile.component"
        ).then(
        (editPatientProfileComponent) =>
          editPatientProfileComponent.EditPatientProfileComponent
      ),
  },
];

import {
  Action,
  NgxsAfterBootstrap,
  State,
  StateContext,
  Store,
} from "@ngxs/store";
import { JOURNAL_ITEM_ENTRY_LIST_STATE } from "../../../../../state-name-list/state-names";
import { Injectable } from "@angular/core";
import { JournalItemEntry } from "../../../../sdk/src/lib/types/journal-item";
import {
  AddJournalItemEntryAction,
  FetchJournalItemEntryListAction,
} from "../actions/journal-item-entry-list.actions";
import { map, tap } from "rxjs/operators";
import { RealmClientService } from "../../../../sdk/src/lib/services/realm-client.service";
import { JournalService } from "../../../../sdk/src/lib/services/journal.service";

@State<Array<JournalItemEntry>>({
  name: JOURNAL_ITEM_ENTRY_LIST_STATE,
  defaults: [],
})
@Injectable({
  providedIn: "root",
})
export class JournalItemEntryState implements NgxsAfterBootstrap {
  constructor(
    private _realmClientService: RealmClientService,
    private _store: Store,
    private _journalItemEntryService: JournalService
  ) {}

  ngxsAfterBootstrap() {
    if (
      this._realmClientService.realmClient?.currentUser?.isLoggedIn === true
    ) {
      this._store.dispatch([new FetchJournalItemEntryListAction()]);
    }
  }

  @Action(FetchJournalItemEntryListAction)
  getJournalItemEntryList(stateContext: StateContext<Array<JournalItemEntry>>) {
    return this._journalItemEntryService
      .getJournalEntryByUserList(
        this._realmClientService.realmClient?.currentUser?.customData?.owner_user_id?.toString()
      )
      .pipe(
        map((journalItems) => {
          stateContext.setState([...journalItems]);
        })
      );
  }

  @Action(AddJournalItemEntryAction)
  AddJournalItem(
    stateContext: StateContext<Array<JournalItemEntry>>,
    journalItemEntryPayload: AddJournalItemEntryAction
  ) {
    return stateContext.setState([
      ...stateContext.getState(),
      journalItemEntryPayload.journalEntryItem,
    ]);
  }
}

import { Component } from "@angular/core";
import { ContainerBlockComponent } from "../../../../../sdk/src/lib/container-block/container-block/container-block.component";
import { BlockUtilService } from "../../../../../sdk/src/lib/services/block-util.service";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-uplift-user-container-block",
  templateUrl: "./uplift-user-container-block.component.html",
  styleUrls: ["./uplift-user-container-block.component.css"],
  standalone: true,
  imports: [CommonModule]
})
export class UpliftUserContainerBlockComponent extends ContainerBlockComponent {
  constructor(private blockUtilService: BlockUtilService) {
    super(blockUtilService);
  }
}

import { Inject, Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { Quest } from "../types/quest.interface";
import { BSON } from "realm-web";
import { Protocol } from "../types/protocol";
import { PatientProfile } from "../types/profile";
import { LifePracticeNew } from "../types/life-practice";
import { RealmClientService } from "./realm-client.service";
import { Environment, ENVIRONMENT_TOKEN } from "../types/environment.token";

@Injectable({
  providedIn: "root",
})
export class QuestService {
  constructor(
    private _realmClientService: RealmClientService,
    @Inject(ENVIRONMENT_TOKEN) private _environmentToken: Environment
  ) {}

  setProtocolToAllPatients(counsellor_user_id: string, protocol: Protocol) {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.userProfileDB)
        .collection<PatientProfile>(
          this._environmentToken.userProfileCollection
        )
        .updateMany(
          {
            counsellor_user_id: counsellor_user_id,
          },
          {
            $set: {
              protocol: protocol,
            },
          }
        )
    );
  }

  getQuestCompletedByPatientByReadableID_Count(
    questReadableID: string,
    ownerUserID: String
  ): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.questResultCollection)
        .count({
          readable_id: questReadableID,
          owner_user_id: ownerUserID,
        })
    );
  }

  getQuest(questID: string): Observable<Quest> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .findOne({
          _id: BSON.ObjectID.createFromHexString(questID),
        })
    );
  }

  createQuest(quest: Quest): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .insertOne(quest)
    );
  }

  updateQuest(quest: Quest): Observable<any> {
    delete quest._id;
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .updateOne(
          {
            readable_id: quest._id,
          },
          {
            $set: { ...quest },
          }
        )
    );
  }

  updateQuestWithUrlSlugChange(
    quest: Quest,
    readable_id: string
  ): Observable<any> {
    delete quest._id;
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .updateOne(
          {
            readable_id: readable_id,
          },
          {
            $set: { ...quest },
          }
        )
    );
  }

  getQuestByReadableID(
    questReadableID: string
  ): Observable<Quest | LifePracticeNew> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .findOne({
          readable_id: questReadableID,
        })
    );
  }

  getQuestByReadableIdCount(questReadableID: string): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .count({
          readable_id: questReadableID,
        })
    );
  }

  getQuestByInternalNameCount(questInternalName: string): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .count({
          internal_name: new RegExp(
            ["^", questInternalName, "$"].join(""),
            "i"
          ),
        })
    );
  }
  getQuestBySearchInternalNameString(internalName: string) {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .find({
          internal_name: new RegExp(internalName, "i"),
        })
    );
  }
  getQuestByPrefixIdCount(prefixID: string): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .count({
          prefix_id: prefixID,
        })
    );
  }

  getAllQuests(): Observable<Quest[]> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .find(
          {},
          {
            projection: {
              title: 1,
              type: 1,
              photo: 1,
              readable_id: 1,
            },
          }
        )
    );
  }

  getQuestList(): Observable<Quest[]> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .find(
          {},
          {
            sort: {
              _id: -1,
            },
          }
        )
    );
  }

  getModuleListFromModuleObjectIdList(
    moduleObjectIdList: object[]
  ): Observable<Quest[]> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .find({
          _id: {
            $in: moduleObjectIdList,
          },
        })
    );
  }

  getQuestsFromQuestObjectID_List(
    questObjectID_List: object[]
  ): Observable<Quest[]> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .find({
          _id: {
            $in: questObjectID_List,
          },
        })
    );
  }

  getQuestsAvailableToTherapistCount(): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .count({})
    );
  }

  getQuestCompletedByPatientWithCountList(
    ownerUserID: string
  ): Observable<Quest[]> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.questResultCollection)
        .aggregate([
          {
            $match: {
              owner_user_id: ownerUserID,
            },
          },
          {
            $group: {
              _id: "$readable_id",
              photo: { $first: "$photo" },
              type: { $first: "$type" },
              short_description: { $first: "$short_description" },
              title: { $first: "$title" },
            },
          },
        ])
    );
  }

  getQuestCompletedByPatient(ownerUserID: string): Observable<Quest[]> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.questResultCollection)
        .find(
          {
            owner_user_id: ownerUserID,
          },
          {
            sort: {
              _id: -1,
            },
          }
        )
    );
  }

  getQuestCompletedByPatientForSpecificQuest(
    questReadableID: string,
    ownerUserID: string
  ): Observable<Quest[]> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.questResultCollection)
        .find(
          {
            owner_user_id: ownerUserID,
            readable_id: questReadableID,
          },
          { sort: { _id: -1 } }
        )
    );
  }

  updateUnlockedQuest(therapistID: string, quest: Quest) {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.userProfileDB)
        .collection<PatientProfile>(
          this._environmentToken.userProfileCollection
        )
        .updateOne(
          {
            owner_user_id: therapistID,
          },
          {
            $addToSet: {
              unlocked_quest_list: quest,
            },
          }
        )
    );
  }

  getLastCompletedQuestListFromPatientList(
    patientUserID_List: string[]
  ): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.questResultCollection)
        .aggregate([
          {
            $match: {
              owner_user_id: {
                $in: patientUserID_List,
              },
            },
          },
          {
            $sort: {
              _id: -1,
            },
          },
          {
            $group: {
              _id: "$owner_user_id",
              title: { $first: "$title" },
            },
          },
        ])
    );
  }

  getSessionListCountForPatient(ownerUserID): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.questResultCollection)
        .aggregate([
          {
            $match: {
              owner_user_id: ownerUserID,
            },
          },
          {
            $group: {
              _id: "$readable_id",
              count: { $sum: 1 },
              first_member_id: { $first: "$_id" },
            },
          },
        ])
    );
  }

  getCompletedSessionGroupedByProtocol(ownerUserID): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.questResultCollection)
        .aggregate([
          {
            $match: {
              owner_user_id: ownerUserID,
            },
          },
          {
            $group: {
              _id: "$readable_id",
              protocol_id: { $first: "$protocol_id" },
              object_id: { $first: "$_id" },
            },
          },
        ])
    );
  }

  setCurrentQuestBeingExecutedByUser(quest: Quest): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.currentProgressCollection)
        .updateOne(
          {
            owner_user_id: quest.owner_user_id,
          },
          {
            $set: { ...quest },
          },
          {
            upsert: true,
          }
        )
    );
  }

  removeCurrentQuestBeingExecutedByUser(ownerUserID: string): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.currentProgressCollection)
        .deleteMany({
          owner_user_id: ownerUserID,
        })
    );
  }

  getCurrentQuestBeingExecutedByUser(ownerUserID: string): Observable<Quest> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.currentProgressCollection)
        .findOne({
          owner_user_id: ownerUserID,
        })
    );
  }

  getLastCompletedSessionOrAssessmentIntervention(userID: string) {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.questResultCollection)
        .findOne(
          {
            owner_user_id: userID,
            type: { $in: ["session", "assessment"] },
          },
          {
            sort: { _id: -1 },
          }
        )
    );
  }

  getQuestBySearchTitleString(questTitle: string) {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .find({
          title: new RegExp(questTitle, "i"),
        })
    );
  }
}

import { JournalItemEntry } from "../../../../sdk/src/lib/types/journal-item";

export class FetchJournalItemEntryListAction {
  static readonly type =
    "[Fetch Journal Item Entry] Fetch Journal Item Entry List";
  constructor() {}
}

export class AddJournalItemEntryAction {
  static readonly type =
    "[Create Journal Item Entry Page] Create Journal Item Entry Page";
  constructor(public journalEntryItem: JournalItemEntry) {}
}

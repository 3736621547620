<div class="block full-width rounded">
  <div
    *ngIf="data?.header?.length > 0"
    class="block-header full-width p-3 pb-0"
  >
    {{ data?.header }}
  </div>
  <div class="block-body full-width">
    <div class="p-3 free-text-block full-width">
      <fieldset class="form-group">
        <textarea
          (focusin)="keyboardAboutToShow()"
          (focusout)="keyboardAboutToHide()"
          *ngIf="!isInputDisabled"
          [formControl]="inputFormControl"
          [id]="data.id"
          [maxlength]="data?.config?.input_character_limit || 500"
          [placeholder]="data.config.placeholder"
          [value]="data.value || ''"
          class="form-control rounded"
          rows="4"
        ></textarea>

        <textarea
          *ngIf="isInputDisabled"
          [id]="data.id"
          [maxlength]="data?.config?.input_character_limit || 500"
          [placeholder]="data.config.placeholder || ''"
          [value]="data.value || ''"
          class="form-control rounded"
          readonly
          rows="4"
        ></textarea>
        <span *ngIf="isFreeTextShow"
              class="badge badge-light mt-2">Character Count : {{inputFormControl?.value?.length || 0}}
          / {{data?.config?.input_character_limit || 500}}</span>
      </fieldset>
    </div>
  </div>
</div>

import { AfterViewInit, Component } from "@angular/core";
import { SelectBlockComponent } from "../../../../../sdk/src/lib/select-block/select-block/select-block.component";
import { SessionStoreService } from "../../../../../sdk/src/lib/services/session-store.service";
import { CommonModule } from "@angular/common";
import { NgSelectModule } from "@ng-select/ng-select";
import { ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "app-uplift-user-select-block",
  templateUrl: "./uplift-user-select-block.component.html",
  styleUrls: ["./uplift-user-select-block.component.css"],
  standalone: true,
  imports: [CommonModule, NgSelectModule, ReactiveFormsModule]
})
export class UpliftUserSelectBlockComponent extends SelectBlockComponent
  implements AfterViewInit {
  constructor(sessionStore: SessionStoreService) {
    super(sessionStore);
  }
  ngAfterViewInit(): void {
    const INPUT_FORM_CONTROL_VALUE = this.inputFormControl.value;
    if (!INPUT_FORM_CONTROL_VALUE) {
      this.inputFormControl.setValue(null);
    }
  }
}

<div class="block full-width rounded">
  <ng-container *ngIf="data?.options.length <= 5; else multiSelect">
    <div
      class="block-header full-width p-3 pb-0"
      *ngIf="data?.header?.length > 0"
    >
      {{ data?.header }}
    </div>
    <div class="block-body full-width">
      <form
        class="form pb-5 mb-0"
        [formGroup]="inputFormControl"
        *ngIf="inputFormControl"
      >
        <ng-container *ngFor="let checkBoxButton of data.options; index as i">
          <div *ngIf="!isInputDisabled" class="custom-checkbox">
            <input
              #CheckBox
              *ngIf="inputFormControl.get(data?.position.toString())"
              (change)="
                onSelectionChanged($event, data.id, checkBoxButton.position)
              "
              [id]="data.id + checkBoxButton.position.toString()"
              [formControlName]="checkBoxButton.position.toString()"
              [value]="checkBoxButton.position.toString()"
              [attr.name]="data.id + checkBoxButton.position.toString()"
              type="checkbox"
            />
            <label [attr.for]="data.id + checkBoxButton.position.toString()">
              {{ checkBoxButton.label }}
            </label>
          </div>

          <div
            [class.no-border-checkbox]="
              isSelectionChecked(checkBoxButton.position, data.value) === true
            "
            *ngIf="isInputDisabled"
            class="custom-checkbox"
          >
            <input
              #CheckBox
              *ngIf="inputFormControl.get(data?.position.toString())"
              [checked]="
                isSelectionChecked(checkBoxButton.position, data.value)
              "
              [id]="data.id + checkBoxButton.position.toString()"
              [disabled]="isInputDisabled"
              [value]="checkBoxButton.position.toString()"
              [attr.name]="data.id + checkBoxButton.position.toString()"
              type="checkbox"
            />
            <label [attr.for]="data.id + checkBoxButton.position.toString()">
              {{ checkBoxButton.label }}
            </label>
          </div>
        </ng-container>

        <div
          class="custom-checkbox"
          *ngIf="
            data?.config?.other_field !== undefined &&
            data?.config?.other_field?.selection_field_text
          "
        >
          <input
            #CheckBox
            *ngIf="inputFormControl.get(data?.position.toString())"
            (change)="onSelectionChanged($event, data.id, data.options.length)"
            [checked]="isSelectionChecked(data.options.length, data.value)"
            [id]="data.id + data.options.length.toString()"
            [formControlName]="data.options.length.toString()"
            [value]="data.options.length"
            [attr.name]="data.id + data.options.length.toString()"
            [disabled]="isInputDisabled"
            type="checkbox"
          />

          <label [attr.for]="data.id + data.options.length.toString()">
            <ng-container
              *ngIf="data?.config?.other_field?.selection_field_text === ''"
            >
              {{ data?.config?.other_field?.selection_field_text }}
            </ng-container>

            <ng-container
              *ngIf="data?.config?.other_field?.selection_field_text !== ''"
            >
              {{ data?.config?.other_field?.selection_field_text }}
            </ng-container>
          </label>
        </div>

        <ng-container *ngIf="isOtherFieldSelected(data)">
          <fieldset class="form-group">
            <label [for]="data?.id + 'other-field'">{{
              data?.config?.other_field.label || ""
            }}</label>

            <input
              *ngIf="mode === 'locked'"
              [value]="data?.config?.other_field?.value || ''"
              readonly
              type="text"
              class="form-control"
              [id]="data?.id + 'other-field'"
              [placeholder]="data?.config?.other_field.placeholder || ''"
            />

            <input
              *ngIf="mode !== 'locked'"
              [formControl]="inputFormControl?.get('OTHER_FIELD')"
              (input)="onOtherFieldChange($event)"
              [value]="data?.config?.other_field?.value || ''"
              [readonly]="isInputDisabled"
              type="text"
              class="form-control"
              [id]="data?.id + 'other-field'"
              [placeholder]="data?.config?.other_field.placeholder || ''"
            />
          </fieldset>
        </ng-container>
      </form>
    </div>
  </ng-container>
  <ng-template #multiSelect>
    <div
      class="block-header full-width p-3 pb-0"
      *ngIf="data?.header?.length > 0"
    >
      {{ data?.header }}
    </div>
    <div class="block-body full-width">
      <form
        class="form pb-5 mb-0"
        [formGroup]="inputFormControl"
        *ngIf="inputFormControl"
      >
        <ng-container *ngIf="isInputDisabled">
          <ng-select
            [multiple]="true"
            *ngIf="data?.options.length"
            bindLabel="label"
            bindValue="position"
            [formControl]="multiSelectFormControl"
            [items]="data?.options"
            [placeholder]="data?.config?.placeholder"
            [readonly]="true"
            [id]="'multi-select-'+data?.id"
          ></ng-select>
        </ng-container>
        <ng-container *ngIf="!isInputDisabled">
          <ng-select
            [multiple]="true"
            *ngIf="data?.options.length"
            bindLabel="label"
            bindValue="position"
            [formControl]="multiSelectFormControl"
            [items]="data?.options"
            [placeholder]="data?.config?.placeholder"
            [readonly]="false"
            [id]="'multi-select-'+data?.id"
          ></ng-select>
        </ng-container>
      </form>
    </div>
  </ng-template>
</div>

<div class="block full-width rounded">
  <div
    class="block-header full-width p-3 pb-0"
    *ngIf="data?.header?.length > 0"
  >
    {{ data?.header }}
  </div>
  <div class="block-body full-width p-3">
    <vg-player class="bg-transparent">
      <audio
        (playing)="publishNewAudioPlay(data?.id)"
        controlsList="nodownload"
        [vgMedia]="audioTemplateREF"
        #audioTemplateREF
        preload="none"
        controls
        class="full-width"
      >
        <source [src]="sanitizedAudioURL" [type]="audioFormatString" />
      </audio>
    </vg-player>
  </div>
</div>

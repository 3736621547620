import { Component, Input, OnInit } from "@angular/core";
import { FreeTextBlock } from "../../types/block";
import { InputBlockComponent } from "../../input-block/input-block/input-block.component";
import { SessionStoreService } from "../../services/session-store.service";

@Component({
  selector: "ail-free-text-block",
  template: "",
  standalone: true
})
export class FreeTextBlockComponent extends InputBlockComponent
  implements OnInit {
  @Input() declare data: FreeTextBlock;

  constructor(sessionStore: SessionStoreService) {
    super(sessionStore);
  }
}

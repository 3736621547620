export const environment = {
  appID: "ai-leap-ilgjp",
  production: false,
  realm: {
    userProfileDB: "uplift_user",
    userProfileCollection: "profile",
    questResultDB: "orbit_project",
    questResultCollection: "session_results",
    appID: "ai-leap-ilgjp",
    sessionDB: "content",
    sessionCollection: "all_content_test",
    serviceName: "mongodb-atlas",
    protocolDB: "content",
    protocolCollection: "protocols",
    sessionResultsCollection: "session_results",
    sessionResultDB: "result_test",
    assessmentDB: "content",
    assessmentCollection: "all_content_test",
    profileDB: "profile",
    patientProfileCollection: "patient_profile_test",
    allContentDB: "orbit_project",
    allContentCollection: "interventions",
    lifePracticeResultDB: "orbit_project",
    lifePracticeResultCollection: "session_results",
    lifePracticeCollection: "life_practice_test",
    liveQuestDB: "live_quest",
    liveQuestCollection: "orbit_live_quest_test",
    groupProtocolListDB: "orbit_project",
    groupProtocolLisCollection: "set_protocols",
    currentProgressCollection: "interventions_being_executed_test",
    createWebinarFunction: "CREATE_WEBINAR_MEETING_DEV",
    updateWebinarFunction: "CREATE_WEBINAR_MEETING_DEV",
    webinarDB: "orbit_project",
    webinarListCollection: "webinars_test",
    groupsListDB: "orbit_project",
    groupListCollection: "groups_test",
    journalEntryCollection: "journal_item_entries_test",
    databaseName: "orbit_project",
    organisationCollection: "organisations_dev",
  },
};

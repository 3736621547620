import { inject, Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { OrbitProtocol, Protocol } from "../types/protocol";
import { PatientProfile } from "../types/profile";
import { RemoteInsertOneResult } from "mongodb-stitch-browser-sdk";
import { RealmClientService } from "./realm-client.service";
import { ENVIRONMENT_TOKEN } from "../types/environment.token";
import { Course, CourseMetadata } from "../types/course";
import { Group } from "../types/group";

@Injectable({
  providedIn: "root",
})
export class ProtocolService {
  private _realmClientService = inject(RealmClientService);
  private _environmentToken = inject(ENVIRONMENT_TOKEN);
  constructor() {}

  getProtocolListOfGroupCode(groupCode: string) {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.groupProtocolListDB)
        .collection(this._environmentToken.groupProtocolLisCollection)
        .findOne({
          group_code: groupCode,
        })
    );
  }

  getProtocolListAvailableToUser(
    createdByUserID?: string
  ): Observable<OrbitProtocol[]> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.groupProtocolListDB)
        .collection(this._environmentToken.groupProtocolLisCollection)
        .find({})
    );
  }

  getAllCourseList(): Observable<Array<Course>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.groupProtocolListDB)
        .collection<Course>(this._environmentToken.groupProtocolLisCollection)
        .find({})
    );
  }

  getGroupByGroupId(assignedGroupId: object) {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.groupProtocolListDB)
        .collection<Group>(this._environmentToken.groupListCollection)
        .findOne({
          _id: assignedGroupId,
        })
    );
  }


  createProtocol(protocol: Protocol): Observable<RemoteInsertOneResult> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.groupProtocolListDB)
        .collection(this._environmentToken.groupProtocolLisCollection)
        .insertOne(protocol)
    );
  }

  createCourse(course: Course): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.groupProtocolListDB)
        .collection(this._environmentToken.groupProtocolLisCollection)
        .insertOne(course)
    );
  }

  updateProtocol(protocol: Protocol, protocolID: object): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.groupProtocolListDB)
        .collection(this._environmentToken.groupProtocolLisCollection)
        .updateOne(
          { _id: protocolID },
          {
            $set: {
              ...protocol,
            },
          }
        )
    );
  }

  assignProtocolToPatient(protocol: Protocol, patientUserID: string) {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.groupProtocolListDB)
        .collection(this._environmentToken.groupProtocolLisCollection)
        .updateOne(
          {
            owner_user_id: patientUserID,
          },
          {
            $set: {
              protocol: protocol,
            },
          }
        )
    );
  }

  getPatientProtocolByUserID(userID: string): Observable<PatientProfile> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.groupProtocolListDB)
        .collection(this._environmentToken.groupProtocolLisCollection)
        .findOne({
          owner_user_id: userID,
        })
    );
  }

  getProtocolCountByName(protocolName: string): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.groupProtocolListDB)
        .collection(this._environmentToken.groupProtocolLisCollection)
        .count({
          name: protocolName,
        })
    );
  }

  getInternalNameCountByName(internalName: string): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.groupProtocolListDB)
        .collection(this._environmentToken.groupProtocolLisCollection)
        .count({
          internal_name: new RegExp(
            ["^", internalName.replace(/[()]/g, "\\$&"), "$"].join(""),
            "i"
          ),
        })
    );
  }

  updateCourseMetadata(
    courseID: object,
    courseMetadata: CourseMetadata
  ): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.groupProtocolListDB)
        .collection<Course>(this._environmentToken.groupProtocolLisCollection)
        .updateOne(
          {
            _id: courseID,
          },
          {
            $set: {
              ...courseMetadata,
            },
          }
        )
    );
  }

  updateCourse(courseID: object, course: Course): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.groupProtocolListDB)
        .collection<Course>(this._environmentToken.groupProtocolLisCollection)
        .updateOne(
          {
            _id: courseID,
          },
          {
            $set: {
              ...course,
            },
          }
        )
    );
  }
}

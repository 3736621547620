import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from "@angular/core";
import { AbstractBlockComponent } from "../../abstract-block/abstract-block/abstract-block.component";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { HtmlBlock } from "../../types/block";
import { isNullOrUndefined } from "@swimlane/ngx-datatable";

@Component({
  selector: "ail-html-block",
  template: "",
  standalone: true
})
export class HtmlBlockComponent extends AbstractBlockComponent
  implements OnInit, OnChanges {
  @Input() declare data: HtmlBlock;
  @Input() mode: string;
  safeHTML: SafeHtml;
  constructor(private _domSanitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {
    if (!isNullOrUndefined(this.data)) {
      this._setSafeHTML();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!isNullOrUndefined(changes["data"].currentValue)) {
      this._setSafeHTML();
    }
  }

  private _setSafeHTML() {
    this.safeHTML = this._domSanitizer.bypassSecurityTrustHtml(this.data.body);
  }
}

import { createAction, props } from "@ngrx/store";
import { Quest } from "../../../../sdk/src/lib/types/quest.interface";
import {
  LifePracticeBatteryOfEnergy,
  LifePracticeNew,
} from "../../../../sdk/src/lib/types/life-practice";
import { C } from "@angular/cdk/keycodes";

export const GET_COMPLETED_QUEST_LIST_ACTION_NAME = "GET_COMPLETED_QUEST_LIST";
export const SET_COMPLETED_QUEST_LIST_ACTION_NAME = "SET_COMPLETED_QUEST_LIST";
export const FETCH_QUEST_BEING_EXECUTED_ACTION_NAME =
  "FETCH_QUEST_BEING_EXECUTED";
export const SET_QUEST_BEING_EXECUTED_ACTION_NAME = "SET_QUEST_BEING_EXECUTED";
export const SET_PROGRESS_BAR_VISIBILITY_STATE_ACTION_NAME =
  "SET_PROGRESS_BAR_VISIBILITY_STATE";
export const SET_PROGRESS_BAR_CURRENT_PAGE_ACTION_NAME =
  "SET_PROGRESS_BAR_CURRENT_PAGE_ACTION";
export const FETCH_BURNOUT_INDICATOR_LIST_ACTION_NAME =
  "FETCH_BURNOUT_INDICATOR_ACTION";
export const SET_BURNOUT_INDICATOR_LIST_ACTION_NAME =
  "SET_BURNOUT_INDICATOR_LIST_ACTION";

export const SET_PROGRESS_BAR_CURRENT_PAGE_ACTION = createAction(
  SET_PROGRESS_BAR_CURRENT_PAGE_ACTION_NAME,
  props<{ page: number }>()
);

export const SET_PROGRESS_BAR_VISIBILITY_STATE_ACTION = createAction(
  SET_PROGRESS_BAR_VISIBILITY_STATE_ACTION_NAME,
  props<{ progressBarState: boolean }>()
);

export const GET_COMPLETED_QUEST_LIST_ACTION = createAction(
  GET_COMPLETED_QUEST_LIST_ACTION_NAME
);

export const SET_COMPLETED_QUEST_LIST_ACTION = createAction(
  SET_COMPLETED_QUEST_LIST_ACTION_NAME,
  props<{ questList: Array<Quest> }>()
);

export const FETCH_QUEST_BEING_EXECUTED = createAction(
  FETCH_QUEST_BEING_EXECUTED_ACTION_NAME,
  props<{ questID: string }>()
);

export const SET_QUEST_BEING_EXECUTED_ACTION = createAction(
  SET_QUEST_BEING_EXECUTED_ACTION_NAME,
  props<{ quest: Quest | any }>()
);

export const FETCH_BURNOUT_INDICATOR_LIST_ACTION = createAction(
  FETCH_BURNOUT_INDICATOR_LIST_ACTION_NAME
);

export const SET_BURNOUT_INDICATOR_LIST_ACTION = createAction(
  SET_BURNOUT_INDICATOR_LIST_ACTION_NAME,
  props<{ lifePracticeBatteryOfEnergyList: LifePracticeBatteryOfEnergy[] }>()
);

export class FetchCompletedQuestAction {
  static readonly type = "[Completed Quest Page] Fetch Completed Quest";
  constructor() {}
}

export class FetchQuestListInProtocolAction {
  static readonly type = "[Home Page] Fetch Quest List In Protocol";
  constructor(public questIdList: Array<object>) {}
}

export class SetQuestListInProtocolAction {
  static readonly type = "[] Set Quest List In Protocol";
  constructor(public questList: Array<Quest>) {}
}

export class FetchQuestBeingExecutedAction {
  static readonly type =
    "[Quest Page] Set Quest Being Executed Based On Router";
  constructor(public questID: string) {}
}

export class UpdateQuestBeingExecutedAction {
  static readonly type = "[Quest Page] Update Quest Being Executed";

  constructor(public quest: Quest | LifePracticeNew) {}
}

export class UpdateProgressBarVisibilityAction {
  static readonly type = "[Quest Page] Update Quest Progress Bar";

  constructor(public isProgressBarVisible: boolean) {}
}

export class UpdateProgressBarPageAction {
  static readonly type = "[Quest Page] Update Quest Progress Number";

  constructor(public pageNumber: number) {}
}

import { Component } from "@angular/core";
import { HtmlBlockComponent } from "../../../../../sdk/src/lib/html-block/html-block/html-block.component";
import { DomSanitizer } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-uplift-user-html-block",
  templateUrl: "./uplift-user-html-block.component.html",
  styleUrls: ["./uplift-user-html-block.component.css"],
  standalone: true,
  imports: [CommonModule]
})
export class UpliftUserHtmlBlockComponent extends HtmlBlockComponent {
  isContentVisible: boolean = false;

  constructor(domSanitizer: DomSanitizer) {
    super(domSanitizer);
  }
}

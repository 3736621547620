import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { AbstractBlockComponent } from "../../abstract-block/abstract-block/abstract-block.component";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { AudioBlock } from "../../types/block";

@Component({
  selector: "ail-audio-block",
  template: ""
})
export class AudioBlockComponent extends AbstractBlockComponent
  implements OnInit {
  @Input() declare data: AudioBlock;
  @Input() mode: string;
  sanitizedAudioURL: SafeUrl;
  audioFormatString: string;
  @ViewChild("audioTemplateREF", { static: true, read: ViewContainerRef })
  _audioTemplateRef;

  constructor(protected _domSanitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {
    this.sanitizedAudioURL = this._domSanitizer.bypassSecurityTrustUrl(
      this.data.source_url_list[0]?.source_url
    );

    this.audioFormatString = `audio/${this.data.source_url_list[0]?.format.toLowerCase()}`;
  }
}

import { Component, Input } from "@angular/core";
import { Block } from "../../types/block";

@Component({
  selector: "ail-abstract-block",
  template: "",
  standalone: true
})
export class AbstractBlockComponent {
  @Input() data: Block;
}
